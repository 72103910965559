body {
  margin: 0;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: white !important; */
  font-family: "Lato", sans-serif !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.container {
  padding: 2em;
}
.title-dashboard {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #aaaaaa !important;
}

.box-item {
  box-shadow: 0px 1px 3px rgba(59, 74, 116, 0.24) !important;
  border-radius: 4px !important;
  overflow: hidden !important;
}

.box-container-full-width {
  padding-left: 0px !important;
  padding-right: 0px !important;
  background-color: white;
  position: absolute;
  width: 100%;
  height: auto;
  left: 0px;
  top: 56px;
  padding: 24px;
  padding-bottom: 7rem;
}

.box-filter-pendanaan {
  color: #aaaaaa;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  padding: 8px 15px;
  width: 100%;
  height: 37px;
  margin: 0.3rem;
  background: #eeeeee;
  border-radius: 18px;
}

.terdanai-active {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  padding: 8px 15px;
  width: 100%;
  height: 37px;
  border-radius: 18px;
  margin: 0.3rem;
  color: #ffffff;
  background: #2fb849;
}

.modalbottom {
  position: absolute !important;
  bottom: 0 !important;
  width: -webkit-fill-available !important;
}
.am-input-item {
  font-family: "Lato", "Helvetica Neue", sans-serif !important;
  background: #ffffff;
  border: 1px solid #d8d8d8;
  box-sizing: border-box;
  border-radius: 3px;
  width: fit-content;
  width: 100% !important;
}
.am-list-header {
  color: #1c1c1c !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 14px !important;
  line-height: 20px !important;
  font-weight: 400 !important;
  padding: 15px 15px 9px 0px !important;
}
.list-input {
  width: 100% !important;
}

.text-header {
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 24px;
  text-align: right;
  color: #1c1c1c;
}

.text-header-green {
  color: #59ac5a !important;
}

.text-loading {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #e0e0e0;
}

.color-green{
  color: #26245E;
}

.label-input {
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 10px;
  color: #8a8a8a;
}

.box-container {
  background-color: white;
  position: absolute;
  width: 100%;
  height: auto;
  left: 0px;
  top: 56px;
  padding: 24px;
  padding-bottom: 7rem;
}

.button-small-white {
  font-size: 12px;
  color: white;
}

.box-dropzone {
  background: #ffffff;
  border: 1px dashed #dadada;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 1.2rem;
  text-align: center;
}

.text-bold {
  font-weight: bold !important;
}

sub {
  line-height: 2rem !important;
}

.box-dropzone > .text-label {
  font-size: 11px;
  line-height: 24px;
  color: #8a8a8a !important;
  text-align: center;
  text-transform: capitalize;
}

.border-bottom {
  border-bottom: 1px solid #eeeeee !important;
  padding-top: 10px;
  margin-left: 8%;
}

.text-label {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  color: #aaaaaa;
}

.text-strong {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #333333;
}
.text-label-strong {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  color: #8a8a8a;
}

.link {
  color: rgb(89, 172, 90) !important;
  white-space: break-spaces !important;
  text-decoration: underline !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  font-style: normal !important;
}
.link-without-underline {
  color: rgb(89, 172, 90) !important;
  white-space: break-spaces !important;
  text-decoration: unset !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  font-style: normal !important;
}

.text-paragraph {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 23px;
  color: #000000;
  white-space: pre-wrap;
  word-break: break-word;
}

.btn-not-sticky-bottom {
  background: #26245E !important;
  border-radius: 6px !important;
  width: 100%;
  border-color: unset !important;
  height: 48px;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  border: none;
  outline: none;
  text-align: center;
  color: #ffffff;
  background: #26245E;
}

.btn-not-sticky-bottom-light {
  background: transparent !important;
  border-radius: 6px !important;
  width: 100%;
  border-color: unset !important;
  height: 48px;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  border: 1px solid #26245E!important;
  outline: none;
  text-align: center;
  color: 26245E;
}
.btn-secondary:hover,
.btn-secondary:focus,
.btn-secondary:active,
.btn-secondary.active {
  background: #26245E !important;
  border-color: unset !important;
}

.underline {
  text-decoration: underline !important;
  color: #2fb849 !important;
}

.btn-not-sticky-bottom:disabled {
  background: #ADB3BC !important;
  border-radius: 6px;
  width: 100%;
  border-color: unset !important;
  height: 50px;
  bottom: 0px;
  font-weight: 800 !important;
}

.btn-sticky {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  bottom: 2rem;
  right: 0;
  outline: none;
  width: 50%;
  height: 50px;
  font-style: normal;
  font-weight: 600!important;
  font-size: 14px;
  line-height: 22px;
  border: none;
  text-align: center;
  color: #ffffff;
  background: #26245E;
  box-shadow: 0px 8px 15px rgba(4, 3, 29, 0.2);
  border-radius: 80px;
}

.flying-btn {
  margin: auto;
  width: 90% !important;
  bottom: 1rem !important;
  outline: none;
  border-radius: 6px;
}

.btn-sticky:focus {
  outline: none !important;
  border: 1px solid #26245E;
}

.btn-not-sticky-bottom:focus {
  outline: none !important;
  border: 1px solid #26245E;
}

.btn-sticky:disabled {
  outline: none !important;
  background: #ADB3BC !important;
}

.container-icon {
  margin: auto !important;
}

.item-tab-button {
  flex: 1 !important;
  padding-bottom: 25px !important;
  background: #26245E !important;
  border: none;
}

strong {
  font-weight: 800;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */

  color: #333333;
}

.prepend-input-currency > .btn-secondary {
  background-color: #ffff !important;
  color: #1c1c1c !important;
  border: 1px solid #ced4da !important;
  border-right: unset !important;
  height: unset;
  font-size: 14px;
  padding-right: unset;
  border: unset !important;
  background-color: transparent !important;
  margin-bottom: 0.2rem !important;
}

.input-currency {
  margin-left: -2.2rem !important;
  padding: 1.5rem !important ;
  width: 100%;
  position: absolute;
  border-radius: 3px !important;
  padding-left: 2.5rem !important;
  font-size: 14px !important;
}

.input-currency:focus {
  border-color: unset !important;
  box-shadow: unset !important;
  outline: 0 none !important;
  background-color: transparent !important;
  outline: none !important;
  border: 1px solid #88db36 !important;
}

.input-currency:disabled {
  background: #ADB3BC !important;
}

.box-button {
  background: #f5f6f7;
  border: 1px solid #dadada;
  box-sizing: border-box;
  border-radius: 4px;
  color: #000000 !important;
  font-weight: 800;
  padding: 0.5rem !important;
  margin-right: 0.7rem;
  font-size: 12px;
}

input {
  width: 100%;
  height: 46px;
  background: #ffffff;
  border: 1px solid #d8d8d8;
  box-sizing: border-box;
  border-radius: 3px;
  padding-left: 10px;
}

input.checkbox {
  height: 20px;
  background: #ffffff;
}
input.disable {
  background: #d8d8d8 !important;
}

.label-checkbox {
  margin-left: -0.5rem !important;
  margin-top: 0.2rem !important;
}

.select {
  height: 46px;
}

ul {
  list-style-type: none;
  margin-left: -1rem;
}

ul > li:before {
  content: "–"; /* en dash */
  position: absolute;
  margin-left: -1.1em;
}

input:focus {
  outline: none !important;
  border: 1px solid #26245E;
}

input.error {
  outline: none !important;
  border: 1px solid #e57373;
  /* background: #fef6f6; */
}

.divider-1 {
  margin-top: 1rem;
}

.divider-2 {
  margin-top: 1.5rem;
}

.divider-3 {
  margin-top: 3rem;
}

.divider-3-half {
  margin-top: 3.5rem;
}

.divider-4 {
  margin-top: 4rem;
}

.divider-5 {
  margin-top: 5rem;
}

.no-shadow {
  box-shadow: none !important;
}
.label-error {
  font-size: 10px;
  color: red;
  font-style: italic;
  float: right;
  padding-right: 2px;
}

.label-green {
  font-size: 10px;
  color: #59ac5a;
  font-style: italic;
}

.underline-input {
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 20px;
  color: #333333;
  font-style: italic;
}

.Toastify__toast-container {
  position: fixed !important;
  text-align: center;
  /* center the element */
  right: 0 !important;
  left: 100 !important;
  margin-right: auto !important;
  margin-left: auto !important;
  /* give it dimensions */
  min-height: 10em !important;
  width: 90% !important;
  max-width: fit-content;
}

.Toastify__toast {
  max-width: 17rem;
  font-size: 14px;
  font-family: "Lato", "Helvetica Neue", sans-serif !important;
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
  margin-top: 1rem;
  border-radius: 0.2rem !important;
  min-height: 10% !important;
  background: #4d4d4d !important;
}

.Toastify__toast-body {
  white-space: pre-line;
}

.sm-extra {
  width: 30%;
  margin-right: auto !important;
  margin-left: auto !important;
}

.outer-container {
  z-index: 99999;
  position: fixed;
  display: table;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  /* background-color: white; */
}

.menunggu{
  animation: dots 1s steps(5, end) infinite;
}

@keyframes dots {
  0%, 20% {
    color: rgba(0,0,0,0);
    text-shadow:
      .25em 0 0 rgba(0,0,0,0),
      .5em 0 0 rgba(0,0,0,0);}
  40% {
    color: white;
    text-shadow:
      .25em 0 0 rgba(0,0,0,0),
      .5em 0 0 rgba(0,0,0,0);}
  60% {
    text-shadow:
      .25em 0 0 white,
      .5em 0 0 rgba(0,0,0,0);}
  80%, 100% {
    text-shadow:
      .25em 0 0 white,
      .5em 0 0 white;}}

.inner-container {
  z-index: 99999;
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}

.centered-content {
  z-index: 99999;
  display: inline-block;
  text-align: left;
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.5);
  /* border-radius: 20px; */
  /* border: 1px solid whitesmoke; */
}
.inner-loading {
  text-align: center;
}
.capitalize {
  text-transform: capitalize;
}

.not-agree {
  display: inline !important;
  text-align: center !important;
}

@media (min-width: 360px) and (max-width: 360px) {
  .title-dashboard {
    font-size: 11px !important;
  }

  .wallet-card .balance .total {
    font-size: 10px !important;
  }
}

.txt-blocking-top {
  margin: auto !important;
  justify-content: center;
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #333333;
}

.txt-blocking-bottom {
  margin: 10px;
  font-style: normal;
  font-size: 12px;
  line-height: 21px;
  text-align: center;
  color: #aaaaaa;
}

.button-inverse {
  background: transparent !important;
  color: #26245E !important;
  border: 1px solid #26245E !important;
}

.button-force-update {
  margin-bottom: -0.7rem !important;
}

.modal-force-update {
  width: 280px !important;
  margin: auto !important;
}

.btn-secondary:hover,
.btn-secondary:focus,
.btn-secondary:active,
.btn-secondary.active {
  /* background: transparent !important; */
}


.centered-all{
    display: flex;
    align-items: center;
    justify-content: center;
}

.centered-vertical-right{
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.input{
  background: #F4F5FC;
  border: 1px solid #DDDDDD;
  box-sizing: border-box;
  border-radius: 8px;
  font-size: 14px;
  height: 44px;
  color: #131324;
  padding: 1rem;
}

.input-error{
  background: #FDF9F9;
  border: 1px solid #E25F57;
  box-sizing: border-box;
  border-radius: 8px;
  font-size: 14px;
  height: 44px;
  color: #131324;
  padding: 1rem;
}

.txt-error{
  font-size: 12px;
  color: #CB3A31;
}

.txt-header{
  font-weight: bold;
  font-size: 20px;
  color: #131324;
}

.txt-subheader{
  font-size: 14px;
  color: #95959D;
}

.container-dashboard{
  padding-top: 1.5rem;
  padding-left: 16px;
  padding-right: 16px;
}

.center-vertical{
  align-items: center;
  justify-content: center;
  display: flex;
}

.center-vertical-left{
  align-items: center;
  display: flex;
}