.icon-payung {
  margin-bottom: 2.3em;
}

.font-tab {
  font-style: normal;
  font-weight: 500 !important;
  font-size: 9px;
  line-height: 12px;
  text-align: center;

  color: #aaaaaa !important;
}

@media (min-width: 320px) and (max-width: 320px) {
  .font-tab {
    font-size: 8px !important;
  }
}

.menu-icon {
  margin-top: 0.7rem;
}
